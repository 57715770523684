import axios from 'axios'
import { apiRootUrl } from "./Types"

export const getFacebookPosts = async (customerId: number, startDate: string, endDate: string) => {
  try{
    const res = await axios.get(`${apiRootUrl}/meta/post-booster/posts/${startDate}/${endDate}/customer/${customerId}`)
    console.log("getFacebookPosts res.data", res.data)
    return res.data 
  }catch(error:any){
    console.error("getFacebookPosts error", error)
    // return error?.response?.data ? error.response.data : "Get Facebook posts error"
  }
 
} 


export const createNewPostBoostingRule = async (customerId: number, postBoostingRule: any, matchedPosts: any ) => {
  try{
    const res = await axios.post(`${apiRootUrl}/meta/post-booster/rules/new/customer/${customerId}`, {postBoostingRule, matchedPosts})
    return res.data 
  }catch(error:any){
    console.error(`createNewPostBoostingRule error:  ${error.response.data}`)
    return error?.response?.data ? error.response.data : "Create new post boosting rule error"
  }
 
} 

export const getAllPostBoostingRules = async (customerId: number) => {
  try{
    const res = await axios.get(`${apiRootUrl}/meta/post-booster/rules/customer/${customerId}`)
    return res.data 
  }catch(error:any){
    console.error(`getAllPostBoostingRules error:  ${error.response.data}`)
    return error?.response?.data ? error.response.data : "Get all post boosting rules error"
  }
 
} 

export const updatePostBoostingRule = async (customerId: number, ruleId: string, data: any) => {
  try{
    const res = await axios.put(`${apiRootUrl}/meta/post-booster/rules/${ruleId}/customer/${customerId}`, data)
    return res.data 
  }catch(error:any){
    console.error(`updatePostBoostingRule error:  ${error.response.data}`)
    return error?.response?.data ? error.response.data : "Update post boosting rule error"
  }
 
} 

export const deltePostBoostingRule = async (customerId: number, ruleId: string) => {
  try{
    const res = await axios.delete(`${apiRootUrl}/meta/post-booster/rules/${ruleId}/customer/${customerId}`)
    return res.status 
  }catch(error:any){
    console.error(`deltePostBoostingRule error:  ${error.response.data}`)
    return error?.response?.data ? error.response.data : "Delete post boosting rule error"
  }
 
} 

