
import { Alert as MaterialAlert, AlertTitle, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { apiClearMetaSettings, apiPutMetaSettings } from '../../api/Customer';
import { useEffect, useState, ChangeEvent } from 'react';
import { MetaSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { apiOAuthLogin } from '../../api/Auth';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setAlert } from '../../context/alert/AlertAction';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import { useStateValue as useMetaStateValue } from '../../context/meta/MetaContext';
import ChooseMetaAssetsDialog from '../ChooseMetaAssetsDialog';
import { regularBtnStyles } from '../../styles/buttons';
import { desktop_h3, desktop_p2, montserratBoldFontFamily } from '../../styles/textStyles';

const textFieldTextStyles = {
  mb: 0.3
}

const gridItemStyles = {
  mt: 1.5,
  mb: 1.5
}

export type MetaAdminProps = {
  customerId: number
}

export const MetaAdmin = (props: MetaAdminProps) => {

  const [metaState, metaDispatch] = useMetaStateValue()
  const [loading, setLoading] = useState(false)
  const [metaSettings, setMetaSettings] = useState<MetaSettings>({
    facebookPageId: '',
    adAccountId: '',
    notifyAuthExpirationDays: 7
  })
  const [showMetaNotifyAuthExpirationDays, setShowMetaNotifyAuthExpirationDays] = useState(false)
  const [{ }, alertDispatch] = useAlertStateValue()

  const metaConfigured = metaState.metaAssets.savedAssetsIntoDB?.facebookPageName

  const doMetaLogin = () => {
    if (props.customerId) {
      setLoading(true)
      apiOAuthLogin("meta", props.customerId).then((response) => {
        window.location.href = response.url
      }).catch((_ => {
        setLoading(false)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Error in authorization. Please try again later.", duration: null }))
      }))
    }
  }

  const onClearMetaSettings = () => {
    if (props.customerId) {
      setLoading(true)
      apiClearMetaSettings(props.customerId).then(() => {
        window.location.reload()
      }).catch((error:any) => {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Clear Meta settings error: ${error?.message}`, duration: null }))
      })
    }
  }

  const onNotifyExpirationDaysChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMetaSettings({ ...metaSettings, notifyAuthExpirationDays: parseInt(event.target.value, 10) })
  }

  const onSetNotifyExpirationDays = () => {
    if (props.customerId && metaSettings.notifyAuthExpirationDays) {
      apiPutMetaSettings(props.customerId, metaSettings.notifyAuthExpirationDays).then(() => {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Notify me expiration days updated", duration: 7000 }))
      }).catch((error) => {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: error.message, duration: null }))
      })
    }
  }

  return (
    <Grid container>
      {loading ? <LoadingModal /> : <Grid container>
        <Grid item xs={12} sx={gridItemStyles}>
          <MaterialAlert severity="info" sx={{ maxWidth: '800px' }}>
            <AlertTitle>Info</AlertTitle>
            Please ensure that you have <b>admin permissions</b> for the relevant Facebook pages and ad accounts that you use in order to effectively perform tasks.
          </MaterialAlert>
        </Grid>

        <Grid item xs={12} sx={gridItemStyles}>
          <Grid container sx={{ width: '100%' }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {metaConfigured && <Grid item xs={3}>
              <Button variant='contained' color="warning" fullWidth={true} onClick={onClearMetaSettings}>Clear assets</Button>
            </Grid>}
            <Grid item xs={9}>
              <Button sx={{ ...regularBtnStyles }} onClick={doMetaLogin}>Authorize</Button>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={metaConfigured ? {...desktop_h3, fontFamily: montserratBoldFontFamily} : {...desktop_p2}}>{metaConfigured ? 'Current configuration' : 'Not configured yet.'}</Typography>
            </Grid>

            {metaConfigured && <>
              <Grid item xs={4}>
                <TextField fullWidth size='small' label="Facebook Page" variant="outlined" value={`${metaState.metaAssets.savedAssetsIntoDB.facebookPageName} - ${metaState.metaAssets.savedAssetsIntoDB.facebookPageId}`} />
              </Grid>

              <Grid item xs={4}>
                <TextField fullWidth size='small' label="Ad Account" variant="outlined" value={`${metaState.metaAssets.savedAssetsIntoDB.adAccountName} - ${metaState.metaAssets.savedAssetsIntoDB.adAccountId}`} />
              </Grid>
            </>}
            <Grid item xs={6}>
              <ChooseMetaAssetsDialog isOpen={metaState.metaAssets.openMetaAssetsDialog} />
            </Grid>
          </Grid>
        </Grid>

        {showMetaNotifyAuthExpirationDays && <>
          <Grid item xs={12} sx={gridItemStyles}>
            <Divider sx={{ mt: 3, mb: 3 }} />
          </Grid>
          <Grid item xs={12} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles} style={{ maxWidth: '800px' }}>
              Please note that Kiva Suite authorization expires every 2 - 3 months. To prompt reauthorization, we will send you a reminder email.
            </Typography>
          </Grid>
          <Grid item xs={6} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Notify me before (days)</Typography>
            <TextField size='small' type='number' variant="outlined" value={metaSettings.notifyAuthExpirationDays} onChange={onNotifyExpirationDaysChange} />
          </Grid>
          <Grid item xs={12} sx={gridItemStyles}>
            <Button variant='contained' color="primary" sx={{ textTransform: 'none' }} onClick={onSetNotifyExpirationDays}>Set</Button>
          </Grid>
        </>}

      </Grid>}
    </Grid>
  )
}