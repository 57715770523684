import moment from "moment"
import { AggregationPeriod, ComparisonPeriod, apiRootUrl } from "./Types"


const handleLoggedOut = async (): Promise<void> => {
  const responseCustomer = await fetch(`${apiRootUrl}/customer`)
  if (responseCustomer.status === 401) {
    window.location.href = "/login"
  }
}

const isEmptyResponse = (res: Response): boolean => !Number(res.headers.get("content-length"))

export const postApi = async <T>(subUrl: string, body: any, defaultResponse?: T): Promise<T> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    if (defaultResponse) {
      return defaultResponse
    } else {
      throw Error(`Error in calling API: ${subUrl}`)
    }
  }
  return (await response.json()) as T
}

export const postApiForm = async <T>(subUrl: string, body: FormData): Promise<T> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "POST",
    body,
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    throw Error(`Error in calling API: ${subUrl}`)
  }
  return (await response.json()) as T
}

export const putApi = async <T>(subUrl: string, body: any, defaultResponse?: T): Promise<T> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    if (defaultResponse) {
      return defaultResponse
    } else {
      throw Error(`Error in calling API: ${subUrl}`)
    }
  }
  return (await response.json()) as T
}

export const patchApi = async <T>(subUrl: string, body: any, defaultResponse?: T): Promise<T> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    if (defaultResponse) {
      return defaultResponse
    } else {
      throw Error(`Error in calling API: ${subUrl}`)
    }
  }
  return (await response.json()) as T
}

export const getApi = async <T>(subUrl: string, defaultResponse?: T): Promise<T> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    if (defaultResponse !== undefined) {
      return defaultResponse
    } else {
      throw Error(`Error in calling API: ${subUrl}`)
    }
  }
  return (await response.json()) as T
}

export const getBase64Api = async <T>(subUrl: string): Promise<string> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) {
    await handleLoggedOut()
  }
  return await response.text()
}

export const deleteApi = async <T>(subUrl: string, defaultResponse?: T): Promise<T> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    if (defaultResponse) {
      return defaultResponse
    } else {
      throw Error(`Error in calling API: ${subUrl}`)
    }
  }
  const text = await response.text()
  if (response.status === 200 && text.length === 0) {
    return {} as T
  }
  return JSON.parse(text) as T
}

export const deleteBodyApi = async <T>(subUrl: string, body: any, defaultResponse?: T): Promise<T> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    if (defaultResponse) {
      return defaultResponse
    } else {
      throw Error(`Error in calling API: ${subUrl}`)
    }
  }
  return (await response.json()) as T
}

export const deleteApiBool = async <T>(subUrl: string, body: any): Promise<boolean> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body
  })
  if (response.status === 401) await handleLoggedOut()
  return response.status >= 200 && response.status < 300
}

export const putApiBool = async <T>(subUrl: string, body: any): Promise<boolean> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) await handleLoggedOut()
  return response.status >= 200 && response.status < 300
}

export const postApiUndefined = async <T>(subUrl: string, body: any): Promise<T | undefined> => {
  const response = await fetch(`${apiRootUrl}/${subUrl}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    return
  }
  return (await response.json()) as T
}

export const getApiText = async (url: string, defaultResponse?: string): Promise<string> => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
  if (response.status === 401) await handleLoggedOut()
  if (response.status >= 300) {
    if (defaultResponse) {
      return defaultResponse
    } else {
      throw Error(`Error in calling API: ${url}`)
    }
  }
  return await response.text()
}

export const getPreviousPeriodDates = (startDate: moment.Moment, endDate: moment.Moment, period: ComparisonPeriod): { startDate: moment.Moment, endDate: moment.Moment } => {
  const dates: { startDate: moment.Moment, endDate: moment.Moment } = {
    startDate: moment(startDate),
    endDate: moment(endDate)
  }
  const daysDiff = endDate.diff(startDate, "days")

  const subs: { [period: string]: any } = {
    "Custom": { days: daysDiff },
    "Week": { weeks: 1 },
    "Month": { months: 1 },
    "Year": { years: 1 }
  }
  dates.startDate = dates.startDate.subtract(subs[period])
  dates.endDate = dates.endDate.subtract(subs[period])
  return dates
}

export const cumulativeArray = (arr: number[]): number[] => {
  const result: number[] = []
  for (const val of arr) {
    result.push(result.length > 0 ? result[result.length - 1] + val : val)
  }
  return result
}

export const aggregationToLabel = (period: AggregationPeriod): string => {
  switch (period) {
    case "date":
      return "day"
    case "week":
      return "week"
    case "month":
      return "month"
  }
}