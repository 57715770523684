import { RGBColor } from "react-color"
import { AdTemplateFile } from "../../api/Types"
import { AdComponentArea } from "../Utils"
import { Animation } from "./AnimationTypes"

export type AdType = 'HTML' | 'Image' | 'Video' | 'Text'

export type AdTemplateInstance = {
  version: 1
  width: number
  height: number
  components: AdComponent[]
}

export interface AdComponent {
  id: string
  type: string
  area: AdComponentArea
  editMode: boolean
  selected?: boolean
}

export interface TextAdComponent extends AdComponent {
  type: "Text"
  text: string
  isDynamic: boolean
  isTimeBased: boolean
  font: string
  fontSize: number
  color: RGBColor
  bold: boolean
  italic: boolean
  underline: boolean
  backgroundColor: RGBColor
  alignHorizontal: "left" | "center" | "right"
  alignVertical: "top" | "center" | "bottom"
  lineHeight?: number
  animation?: Animation
}

export interface ImageAdComponent extends AdComponent {
  type: "Image"
  imageId: string
  imageFile: string
  name: string
  objectFit: "contain" | "cover" | "fill" | "none"
  width: number | string
  height: number | string
  animation?: Animation
}

export interface HtmlAdComponent extends AdComponent {
  type: "Html"
  html: string
  fileId: string
  filename: string;
}

export interface RectangleAdComponent extends AdComponent {
  type: "Rectangle"
  backgroundColor: RGBColor
  borderRadius: {
    all: number,
    setSeparately: boolean,
    topLeft: number,
    topRight: number,
    bottomLeft: number,
    bottomRight: number,
    unit: "px" | "%"
  }
}

export const objectFits = ["none", "contain", "cover", "fill"]

export interface AnimationAdComponent extends AdComponent {
  type: "Lottie"
  animationFile: string
}

export type AdCreateType = "custom" | "template"

export type AdTemplateType = {
  version: 1
  type: AdType
  entryPoint: string
  instances: AdTemplateInstance[]
}

export type AdTemplate = {
  templateId: string
  name: string;
  files: AdTemplateFile[]
  types: AdTemplateType[]
}

export type MediaCategory = "image" | "font" | "archive" | "page" | "script"
export type MediaType = "jpg" | "png" | "woff2" | "woff" | "otf" | "avif" | "zip" | "html" | "javascript"
