import axios from 'axios'
import { CheckoutRequestBody } from '../components/LandingPage/utils'
import { apiRootUrl } from "./Types"


export const createCheckoutSession = async (checkoutRequestBody: CheckoutRequestBody) => {
  try{
    return await axios.post(`${apiRootUrl}/stripe/create-checkout-session`, checkoutRequestBody)
  }catch(error: any){
    console.log("create checkout session error: ", error)
    return error.response?.data
  }
 
} 

export const createPortalSession = async (session_id: string) => {
  try{
    return await axios.post(`${apiRootUrl}/stripe/create-portal-session`, { session_id })
  }catch(error: any){
    console.log("create portal session error: ", error)
    return error.response?.data
  }
}
